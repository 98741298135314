@import '../../styles/variables.module.scss';

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: var(--top-bar-height-mobile);
	background-color: white;
	z-index: 100;

	flex: 0 0 var(--top-bar-height-mobile);

	@media screen and (min-width: $breakpoint-md) {
		height: var(--top-bar-height);
		flex: 0 0 var(--top-bar-height);
	}
}

.content {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: var(--content-max-width);
	text-align: center;
}

.logoLink {
	padding: 0;
	margin: 0;
	line-height: 0;
	transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
	will-change: transform;

	&:hover {
		transform: scale(1.03);
	}
}

.leftSideContainer {
	position: absolute;
	display: flex;
	height: fit-content;
	align-items: center;
	left: 0;
	margin-left: 0.875rem;

	@media screen and (min-width: $breakpoint-md) {
		margin-left: 2rem;
	}
}

.rightSideContainer {
	position: absolute;
	display: flex;
	height: fit-content;
	align-items: center;
	right: 0;
	margin-right: 0.875rem;

	@media screen and (min-width: $breakpoint-md) {
		margin-right: 2rem;
	}
}
