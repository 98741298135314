@import '../../styles/variables.module.scss';

.wrapper {
	position: relative;
	background-color: #A0CDFF;
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;
	overflow: hidden;

}

.page {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	overflow: hidden;

	@media screen and (min-width: $breakpoint-md) {
		height: calc(100vh - var(--top-bar-height));
		//flex-direction: row;
		width: 100vw;
	}
}

.content {
	width: 100%;
	padding: 3.5rem 1.25rem 0;
	max-width: var(--content-max-width);

	@media screen and (min-width: $breakpoint-md) {
		padding: 60px 30px;
		z-index: 100;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1800px) {
		padding: 60px 30px;
	}
}

.headerColor {
	color: white;
}

.header {
	@extend .headerColor;

	width: 21rem;

	@media screen and (min-width: $breakpoint-md) {
		width: 41rem;
	}
}

.headerMobile {
	display: flex;
	flex-direction: column;
}

.headerInnerMobile {
	display: flex;
	flex-direction: row;
}

.heart {
	margin-left: 1rem;
	width: 77px;
	height: 77px;
}

.paragraph {
	width: auto;
	color: white;
	margin-top: 1.5rem;

	@media screen and (min-width: $breakpoint-md) {
		width: 32rem;
		margin-top: 3rem;
	}
}

@keyframes flyUpPosition {
	0% {
		transform: translate(20%, 15%);
	}
	100% {
		transform: translate(0, 0);
	}
}



.mascot {
	position: relative;
	width: calc(100% - 15%);
	height: auto;
	animation: 1s ease-in-out 0s 1 flyUpPosition forwards;

	@media screen and (min-width: $breakpoint-md) {
		position: absolute;
		top: 100px;
		right: 0;
		width: auto;
		height: 1500px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1800px) {
		height: 1300px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1550px) {
		height: 1100px;
	}

	@media screen and (min-width: $breakpoint-md) and (max-width: 1400px) {
		height: 900px;
	}
}

.socialContainer {
	display: flex;
	flex-direction: row;
	gap: 1.5rem;
	margin-top: 1.5rem;

	@media screen and (min-width: $breakpoint-md) {
		margin-top: 3rem;
	}
}

.globePurpleContainer {
	position: absolute;
	top: 15%;
	left: 20%;
}

.globePurple {
	width: 270px;
}

.globeBlueContainer {
	position: absolute;
	right: -10%;
	bottom: -50%;

	@media screen and (max-height: 900px) {
		bottom: -70%;
	}
}

.globeBlue {
	width: 1079px;
}

.globeYellowContainer {
	position: absolute;
	top: 5%;
	left: 80%;
}

.globeYellow {
	width: 1079px;
}
