@import '../../styles/variables.module.scss';

.container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	bottom: 0;
	width: 100%;
	height: 95px;
	background-color: white;
	z-index: 1;
	flex: 0 0 95px;
}

.containerAbsolute {
	position: absolute;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	min-height: 100%;
	max-width: var(--content-max-width);

	@media screen and (min-width: $breakpoint-md) {
		justify-content: space-between;
		flex-direction: row;
		padding: 0 40px;
	}
}

.rightSection {
	display: flex;
	gap: 0.5rem;
	@media screen and (min-width: $breakpoint-md) {
		gap: 2.5rem;
	}
}

.link:link {
	text-decoration: none;
}

.link:visited {
	text-decoration: none;
}

.link:hover {
	text-decoration: none;
}

.link:active {
	text-decoration: none;
}
