@import '../../styles/variables.module.scss';

:global(.socialLinkButton) {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	padding: 0px 20px;
	border: 0;
	background-color: var(--color-white);
	color: var(--color-button-basic);
	border-radius: 48px;
	cursor: pointer;
}

.buttonDisabled {
	opacity: 0.5;
}

.buttonHover:hover {
	color: var(--color-white);
	background-color: var(--color-purple-dark);
}

.button:hover .svgHoverFill {
	fill: white;
}
