.split-screen-container {
	display: flex;
	min-width: 100%;
	overflow: hidden;
	z-index: 1;

	@media screen and (min-width: $breakpoint-md) {
		flex-direction: row;
	}
}

// .split-left-section,
// .split-right-section {
// 	display: flex;
// 	flex: 1;
// 	overflow: hidden;
// }

.split-left-section,
.split-right-section {
	flex: 1;
}

@media screen and (min-width: $breakpoint-md) {
	.split-left-section,
	.split-right-section {
		display: flex;
		flex: 1;
		overflow: hidden;
	}
}

@media screen and (max-width: $breakpoint-md) {
	.hide-on-mobile {
		display: none;
	}
}

@media screen and (min-width: $breakpoint-md) {
	.hide-on-desktop {
		display: none;
	}
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.margin-top-1 {
	margin-top: 1rem;
}

.margin-top-2 {
	margin-top: 2rem;
}

.margin-top-3 {
	margin-top: 3rem;
}

.margin-top-4 {
	margin-top: 4rem;
}

.text-align-left {
	text-align: left;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.card {
	position: relative;
	top: 30px;
	left: 0;
	transform: none;
	right: 20px;
	display: flex;
	min-width: calc(100% - 40px);
	min-height: 350px;
	padding: 40px;
	background-color: white;
	border-style: solid;
	border-width: 2px;
	border-top-width: 10px;
	box-shadow: 49px 55px 30px rgba(0, 0, 0, 0.01),
		28px 31px 25px rgba(0, 0, 0, 0.03), 12px 14px 19px rgba(0, 0, 0, 0.04),
		3px 3px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	z-index: 1;

	@media screen and (max-width: $breakpoint-xxl) and (min-width: $breakpoint-md) {
		left: 20px;
	}

	@media screen and (min-width: $breakpoint-xxl) and (min-width: $breakpoint-md) {
		left: auto;
	}

	@media screen and (min-width: $breakpoint-md) {
		position: absolute;
		min-width: 480px;
		top: 50%;
		right: 50%;
		transform: translate(0%, calc(var(--top-bar-height) / 2 - 50%));
	}
}
