@import 'normalize.scss';
@import './variables.module.scss';
@import './general.module.scss';

@font-face {
	font-family: 'PP Mori';
	src: url(./fonts/PPMori-Regular.otf) format('opentype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'PP Mori';
	src: url(./fonts/PPMori-SemiBold.otf) format('opentype');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

:root {
	--font-primary: 'PP Mori', sans-serif;

	--color-black: #1b1b1b;
	--color-white: #ffffff;
	--color-grey: #676767;
	--color-purple: #d4d6ff;
	--color-purple-dark: #1f0061;
	--color-bg-yellow: #ffe6aa;
	--color-bg-purple: #9b9eff;
	--color-bg-green: #c8fdda;
	--color-bd-yellow: #fff2cf;
	--color-bd-purple: #d1e7ff;
	--color-bd-green: #ddffeb;
	--color-button-basic: #9b9eff;
	--color-button-basic-hover: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		var(--color-button-basic);
	--color-button-basic-text: #ffffff;
	--color-button-white: var(--color-white);
	--color-button-white-hover: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0.2)
		),
		var(--color-white);
	--color-button-white-text: var(--color-button-basic);

	--top-bar-height: 82px;
	--top-bar-height-mobile: 46px;
	--content-max-width: 1800px;
}

html {
	font-family: var(--font-primary);
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100%;
	// Fix for page2 not showing
	// height: 100%;
	overflow-x: hidden;
}

:where(input:not(input[type='radio'])) {
	all: unset;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p {
	height: fit-content;
	margin: 0;
}

h1 {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 1;
	color: var(--color-black);

	@media screen and (min-width: $breakpoint-md) {
		font-size: 5rem;
	}
}

h2 {
	@extend h1;

	font-size: 2.5rem;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 3rem;
	}
}

h3 {
	@extend h1;

	font-size: 2rem;
	line-height: 1;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 2.25rem;
		line-height: 1.2;
	}
}

h4 {
	@extend h1;

	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1rem;
	}
}

h5 {
	@extend h1;

	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.25;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1rem;
	}
}

h6 {
	@extend h1;

	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.42;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1.3125rem;
		line-height: 1.2857;
	}
}

p,
li,
fieldset {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: clamp(0.6rem, calc(5vw - 0.5rem), 0.875rem);
	line-height: 1.43;
	color: var(--color-black);

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1.3125rem;
		line-height: 1.5;
	}
}

a {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 600;
	font-size: 0.625rem;
	line-height: 2.4;
	white-space: nowrap;
	color: #7f8596;
	margin-top: 0.625rem;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 0.875rem;
		line-height: 1.714;
		margin-top: 0;
	}
}

td,
th {
	@extend p;

	padding: 1rem 0;

	white-space: nowrap;
}

tfoot tr td {
	@extend h1;

	font-size: 1rem;
	line-height: 2;

	@media screen and (min-width: $breakpoint-md) {
		font-size: 1.75rem;
	}
}

tbody,
tfoot {
	border-top: 1px solid black;
}

tbody.disabled,
tfoot.disabled {
	border-top: 1px solid #c8c8c8;
}

.daoLink {
	@extend p;

	margin-left: 0.5rem;
	text-decoration: underline;
	color: var(--color-black);
	font-weight: 800;
	cursor: pointer;
}

.link {
	@extend p;

	margin-left: 0.25rem;
	text-decoration: underline;
	color: var(--color-black);
	font-weight: 800;
	cursor: pointer;
}
