@import '../../styles/variables.module.scss';

.faq {
  display: flex;
  font-family: 'PP Mori';
  font-weight: 600;
  color: #052549;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  background-color: #d1e7ff;
  padding-top: 40px;
  @media screen and (max-width: 767px) {
    font-size: 4rem;
  }
}
.page {
  display: flex;
  font-family: 'PP Mori';
  @media screen and (max-width: 767px) {
    padding: 0 40px 40px 40px;
  }
  @media screen and (min-width: 768px) {
    padding: 0 60px 60px 60px;
  }
}
.pageOne {
  color: #052549;
  background-color: #d1e7ff;
  p {
    color: #052549;
  }
}
.pageTwo {
  color: #382800;
  background-color: #ffe6aa;
  p {
    color: #382800;
  }
}
.pageThree {
  color: #1b1b1b;
  background-color: #ebebff;
  flex-direction: column;
  p {
    color: #1b1b1b;
  }
}
.pageFour {
  color: #1f0061;
  background-color: #d4d6ff;
  p {
    color: #1f0061;
  }
}
.pageFive {
  color: #1f0061;
  background-color: #d4d6ff;
  flex-direction: column;
  p {
    color: #1f0061;
  }
}
.emojiContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    width: 25vw;
    img {
      width: 23vw;
    }
  }
}
.pictureTextContainer {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.pictureContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 30px;
  @media screen and (max-width: 767px) {
    img {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    width: 50vw;
    img {
      width: 100%;
    }
  }
}
.textContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.pageTitle {
  display: flex;
  font-size: 30px;
  font-weight: 600;
  margin-top: 64px;
}
.pagSecondaryTitle {
  display: flex;
  font-size: 26px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 12px;
}
.pageDesc {
  font-size: 20px;
  margin-bottom: 32px;
  letter-spacing: 0.01em;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
  .discord {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 400px;
    }
  }
}

.listHeading {
  font-size: 20px;
  letter-spacing: 0.01em;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
  .discord {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 400px;
    }
  }
}

.schedule {
  // display: inline-block;
  // flex-wrap: wrap;
  // align-items: start;
  // justify-content: start;
  font-size: 20px;
  letter-spacing: 0.01em;
  align-content: center;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
  .discord {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 400px;
    }
  }
}
.pageList {
  list-style-type: square;
  margin-bottom: 32px;
  li {
    font-size: 20px;
    letter-spacing: 0.01em;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.pagePadding {
  padding-left: 30px;
  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
}
.notice {
  font-style: italic;
  font-weight: 600;
}

.introLink {
  display: inline-flexbox;
  align-items: center;
  text-decoration: none;
  font-size: 22px;
  cursor: pointer;
  border: 3px #fff solid;
  border-radius: 9999px;
  color: #fff;
  background-color: #A0CDFF;
  box-shadow: 0px -1px 2px 1px #A0CDFF inset;
  padding: 6px 12px 6px 8px;
  margin: 0 3px 0 3px;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.addrLink {
  // text-decoration: underline;
  font-size: 22px;
  cursor: pointer;
  color: #052549;
}

.introLink:hover {
  background-color: #72b5f8;
}

.disclaimer {
  color: #737373;
}

.disclaimerMessage {
  margin-top: 1rem;
  font-size: 2rem;
  border-left: 3px solid var(--color-bg-purple);
  padding-left: 0.5rem;
}

.btnIcon {
  // position: relative;
  align-self: center;
  margin-right: 2px;
  padding-top: 5px;
  margin-bottom: -1px;
  @media screen and (max-width: 767px) {
    margin-bottom: -3px;
  }
}
